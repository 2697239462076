<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0">
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                :clearable="false" class="per-page-selector v-select-custom" @input="pageSize" />
              <label for="">{{ $t('entries') }}</label>
              <span class="ml-1 text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>
          </b-row>
        </div>
        <b-table ref="refUserListTable" striped responsive class="position-relative" :items="respData"
          :fields="tableColumns" primary-key="_id" show-empty :empty-text="$t('No matching records found')"
          style="height: 60vh;">
          <template #cell(id)="data">
            <p class="align-text-bottom line-height-1">
              {{ data.item._id.substring(18) }}</p>
          </template>
          <template #cell(name)="data">
            <p class="align-text-bottom line-height-1">
              {{ data.item.name }}</p>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="viewReport(data.item._id)">
                <feather-icon icon="ReportIcon" />
                <span class="align-middle ml-50">{{ $t('View Report') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

              <b-pagination v-model="currentPage" :total-rows="queriedItems" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @input="nextPage">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import perPageOptions from '@/perPageOptions'
import store from '@/store'
import {
  BAvatar, BButton, BCard, BCol, BFormCheckbox, BFormInput, BImg, BOverlay, BPagination, BRow, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import storeModule from '@/store/services/hero/questionReviewReport'
import { avatarText } from '@core/utils/filter'
import debounce from 'debounce'

const STORE_MODULE_NAME = 'questionReviewReport'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BTable,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BOverlay,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'id', label: this.$t('ID'), sortable: false },
        { key: 'questionInfo', label: this.$t('Question'), sortable: false },
        { key: 'sumScore', label: this.$t('Sum Score'), sortable: false },
        { key: 'count', label: this.$t('Count'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.max : 0
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
    }
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang]
      }
      return ''
    },
    viewReport(id) {
      this.$router.push({ path: `/hero/questionreview-report/${id}` })
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
